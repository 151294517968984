import React, { useEffect, useState } from 'react';
// import EditText from '../../CommonComponents/EditText/EditText'
import Heading from '../../../CommonComponents/Heading/Heading';

import {
  MiddleBodyParent,
  TopSection,
  BottomSection,
  MiddleBodyVideoBackground,
  TopLeftSection,
  TopRightSection,
} from './ModernMiddleBody7.styled';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { renderMediaComponent } from '@/components/DesignSystem/ModernTheme/utils/common';
import { useSelector } from 'react-redux';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernMiddleBody7(props) {
  const [middleBodyState, setMiddleBodyState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (props?.config?.children) {
      setMiddleBodyState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.setAttribute('playsinline', '');
    }
  }, []);

  const renderParentContainer = (bgType) => {
    return (
      <>
        <TopSection>
          <TopLeftSection
            styleConfig={{
              config: { ...middleBodyState?.config },
              contentStyle: { heading: middleBodyState?.heading },
            }}
            textStyles={propStyle}
          >
            {middleBodyState?.heading?.text && (
              <Heading headingData={middleBodyState?.heading} />
            )}
          </TopLeftSection>
          <TopRightSection></TopRightSection>
        </TopSection>
        <BottomSection textStyles={propStyle}>
          {renderMediaComponent(
            {
              id: middleBodyState?.media?.id,
              ...middleBodyState?.media?.foreground,
            },
            'grid',
            '3/4'
          )}
        </BottomSection>
      </>
    );
  };
  return middleBodyState?.background?.type === 'video' ? (
    <MiddleBodyVideoBackground
      id="MT__MiddleBody_Gallery_MB7"
      styleConfig={styleConfig?.config}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody_gallery7 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {' '}
      <video autoPlay loop muted playsInline>
        <source
          src={middleBodyState?.media?.background?.content[0]?.url}
          type="video/mp4"
        />
      </video>{' '}
      <MiddleBodyParent
        id="MT__MiddleBody_Gallery_MB7"
        styleConfig={styleConfig?.config}
        propsBackground={middleBodyState?.media?.background}
        themeBackground={propStyle?.backgroundColor}
        className={`modern_theme_middlebody_gallery7 bg_type_${middleBodyState?.media?.background?.type}`}
      >
        {renderParentContainer('video')}
      </MiddleBodyParent>
    </MiddleBodyVideoBackground>
  ) : (
    <MiddleBodyParent
      id="MT__MiddleBody_Gallery_MB7"
      styleConfig={styleConfig?.config}
      propsBackground={middleBodyState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody_gallery7 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {renderParentContainer('image')}
    </MiddleBodyParent>
  );
}

export default ModernMiddleBody7;
